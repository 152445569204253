<template>
  <div class="content-box-padding">
    <div class="sub-title">功能定位</div>
    <div class="line-text" style="margin-top: 20px; width: 105%">
      形成360°生态环境监管体系、环境质量预警体系、面向政府和企业服务体系、应急指挥体系；<br />
      聚焦一个“中心”，即所有和地理信息有关的数据都显示在同一个GIS地图上，且来自不同系统的数据可以互相对比。
    </div>
    <img
      src="~assets/image/business/生态环境数据信息化1.png"
      alt="生态环境数据信息化1"
      class="img-center"
      style="margin-top: 50px; width: 90%"
    />
    <img
      src="~assets/image/business/生态环境数据信息化2.png"
      alt="生态环境数据信息化2"
      class="img-center"
      style="margin-top: 50px"
    />
  </div>
</template>
